import React, { FC, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import BlogCard from "./BlogCard";
import "react-multi-carousel/lib/styles.css";

interface Props {
  postList: any;
  title: string;
  matchingCategories: string[];
}

const RelatedBlogs: FC<Props> = ({ postList, title = "Related", matchingCategories }) => {
  const carouselRef = useRef<HTMLDivElement | null>(null);

  // Swipe handlers for touch events
  const handlers = useSwipeable({
    onSwipedLeft: () => scrollRight(),
    onSwipedRight: () => scrollLeft(),
  });

  // Scroll handlers
  const scrollLeft = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  // Handle mouse wheel event
  const handleWheel = (e: React.WheelEvent) => {
    e.preventDefault();
    if (carouselRef.current) {
      const scrollAmount = e.deltaY; // Change scroll amount sensitivity as needed
      carouselRef.current.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="md:my-[70px] my-[50px] ">
      <div className="contain">
        <h2 className="xl:text-[65px] lg:text-[55px] text-[32px] font-sofiaMedium font-medium mb-6">
          {title.split(" ")[0]}{" "}
          <span className="text-primary">{title.split(" ")[1]}</span>{" "}
          {title.split(" ")[2]}
        </h2>
      </div>
      <div
        {...handlers}
        className="w-full overflow-x-auto scroll-smooth "
        ref={carouselRef}
        style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
      >
        <div className="flex space-x-4 justify-start pro-slider">
          {postList.map((blog: any, index: any) => (
            <BlogCard
              key={index}
              blog={blog}
              isRelatedPage={true}
            />
          ))}
        </div>
      </div>
    </div>

  );
};

export default RelatedBlogs;
