import React, { FC, useEffect, useState } from "react";
import { HeadFC, Script, graphql } from "gatsby";
import parse from "html-react-parser";
import Clickmagic from "../../components/common/ClickMagic/Clickmagic";
import Layout from "../../components/layout/Layout";
import Seo from "../../components/Seo";
import { getHead } from "../../services/rankMathServices";
import "./blogTemp.css";
import RelatedBlogs from "../../components/blog/RelatedBlogs";
import Breadcrumb from "../../components/common/Breadcrumb";
import { formattedDate } from "../../utils/helper";
import { useLocation } from "@reach/router";

const blogPage = (props: any) => {
	let { data, pageContext } = props;
	const allData: any = data?.wppostData;
	const relatedPosts: any = data?.allWpPost?.nodes;
	const currentCategories: any = data?.wppostData?.categories?.nodes?.map(
		(category: any) => category.slug
	);
	const crumbsData = [
		{ label: "Blog", link: "/blog/" },
		{ label: allData?.title, link: null },
	];

	return (
		<Layout>
			<section className="px-[15px] md:px-[15%] xl:px-[15%] lg:px-[15%] contain pt-[10px] pb-[40px]">
				<Breadcrumb crumbs={crumbsData} />
				<h1 className="xl:text-[45px] lg:text-[35px] text-[25px] mt-[-15px] font-sofiaMedium font-medium text-black ml-[-4px] md:min-h-[40px] min-h-[35px]">
					{allData?.title}
				</h1>
				<div className="flex items-center gap-3 mb-3">
					<div>
						{allData?.author?.node?.avatar?.url && (
							<img
								src={allData?.author?.node?.avatar?.url}
								alt="Spartan Peptide"
								width={60}
								height={60}
								className="  md:w-[66px] w-[30px] md:h-[66px] h-[30px] object-contain rounded-full"
							/>
						)}
					</div>
					<div className="text-[12px] font-sofiaRegular  text-[#81878F] font-normal">
						{allData.author.node.name && (
							<p>
								Written by{" "}
								<span className="!text-black !font-medium !font-sofiaMedium">
									{allData.author.node.name}
								</span>
							</p>
						)}
						{allData.date && <p>Published {formattedDate(allData.date)}</p>}
					</div>
				</div>

				<div className="blog_content_large ">
					{/* {allData?.featuredImage?.node?.publicUrl &&
                    <img
                        src={allData?.featuredImage?.node?.publicUrl}
                        alt="Spartan Peptide"
                        width={243}
                        height={279}
                        className="blog_image w-full h-full object-cover rounded-[21px]"
                    />} */}
					<div dangerouslySetInnerHTML={{ __html: allData?.content }}></div>
				</div>
				<RelatedBlogs
					title="Related Articles"
					postList={relatedPosts}
					matchingCategories={currentCategories}
				/>
			</section>
		</Layout>
	);
};
export default blogPage;

export const query = graphql`
	query ($slug: String) {
		wppostData: wpPost(slug: { eq: $slug }) {
			id
			title
			status
			slug
			content
			date
			author {
				node {
					firstName
					lastName
					name
					avatar {
						url
					}
				}
			}
			categories {
				nodes {
					name
					slug
				}
			}
			featuredImage {
				node {
					publicUrl
				}
			}
			seo {
				fullHead
			}
		}
		allWpPost(filter: { slug: { ne: $slug } }) {
			nodes {
				id
				title
				status
				slug
				content
				date
				author {
					node {
						firstName
						lastName
						name
						avatar {
							url
						}
					}
				}
				categories {
					nodes {
						name
						slug
					}
				}
				featuredImage {
					node {
						publicUrl
					}
				}
			}
		}
	}
`;
export const Head = (props: any) => {
	let { data } = props;
	const location = useLocation();
	const [canonicalIsPresent, setCanonicalIsPresent] = useState<boolean | null>(null);
	const seoInfo = data.wppostData.seo.fullHead;
	const parseHtml: any = parse(seoInfo);

	const index =
		parseHtml &&
		parseHtml?.length > 0 &&
		parseHtml.findIndex(
			(item: any) => item?.props?.type === "application/ld+json"
		);

	if (index > -1) {
		parseHtml.splice(index, 1);
	}


	useEffect(() => {
		const canonicalIndex = parseHtml.findIndex((item: any) => item?.props?.rel === "canonical");
		if (canonicalIndex > -1) {
			setCanonicalIsPresent(true);
		} else {
			setCanonicalIsPresent(false);
		}
	}, [parseHtml]);

	return (
		<>
			{parseHtml}
			{!canonicalIsPresent && <link rel="canonical" href={location.href} />}
			<Seo info={seoInfo} />
		</>
	);
};
